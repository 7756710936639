import * as moment from 'moment-business-days';
import queryString from 'query-string';
import numbro from 'numbro';

import { DATE_FORMAT, DAY_LENGTH, VACATION_STATUS } from './constants';

export function getInitials(user) {
  return user && user.firstName && user.lastName && `${user.firstName[0]} ${user.lastName[0]}`;
}

export function fullName(user) {
  return user && `${user.firstName} ${user.lastName}`;
}

export function getUsedSickDays(firebase, uid, year) {
  let usedSickDays = 0;
  year = parseInt(year);

  return firebase.fetchAllTimeData(uid, year)
    .then(data => data.forEach(month => usedSickDays += month.sickDays.length))
    .then(() => usedSickDays);
}

export function getUsedVacationDays(firebase, dayOffs, uid, year) {
  year = year || moment().year();
  year = parseInt(year);
  let usedVacationDays = 0;

  setHolidays(dayOffs, year);

  return firebase.fetchAllVacationRequests(uid, year)
    .then(requests => requests.filter(request => request.status === VACATION_STATUS.APPROVED))
    .then(vacations => vacations.forEach(vacation => usedVacationDays += vacationBusinessDays(vacation, dayOffs, null, year)))
    .then(() => usedVacationDays);
}

export function vacationBusinessDays(vacation, dayOffs, currentMonth, year) {
  let start = vacation.vacationStart;
  let end = vacation.vacationEnd;
  year = parseInt(year);

  if (dayOffs) {
    setHolidays(dayOffs, year);
  }

  if (currentMonth) {
    currentMonth = moment(currentMonth);
    start = currentMonth.month() !== moment(start, DATE_FORMAT).month() ? currentMonth.startOf('month').format(DATE_FORMAT) : start;
    end = currentMonth.month() !== moment(end, DATE_FORMAT).month() ? currentMonth.endOf('month').format(DATE_FORMAT) : end;
  }

  return businessDiff(start, end);
}

export function setHolidays(dayOffs, year) {
  year = year || moment().year();
  const holidays = formatDayOffs(dayOffs, year).concat(formatDayOffs(dayOffs, year + 1));

  moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5],
    holidays,
    holidayFormat: 'D/M/YYYY',
  });
}

function formatDayOffs(dayOffs, year) {
  return dayOffs.map(dayOff => `${dayOff.day}/${dayOff.month+1}/${year}`);
}

export function businessDiff(startDate, endDate) {
  let diff = 0;
  startDate = moment(startDate, DATE_FORMAT);
  endDate = moment(endDate, DATE_FORMAT);

  startDate = !startDate.isBusinessDay() ? startDate.nextBusinessDay() : startDate;
  endDate = !endDate.isBusinessDay() ? endDate.prevBusinessDay() : endDate;

  let date = startDate;

  while (!date.isAfter(endDate)) {
    diff++;
    date = date.nextBusinessDay();
  }

  return diff;
}

export function monthBusinessDays(month, dayOffs) {
  if (dayOffs) {
    setHolidays(dayOffs, moment(month).year());
  }

  return moment(month).monthBusinessDays();
}

export function getDateFromQuery(query) {
  return queryString.parse(query).date;
}

export function sortUsers(users) {
  return users.sort((u1, u2) => {
    const fullName1 = `${u1.firstName} ${u1.lastName}`;
    const fullName2 = `${u2.firstName} ${u2.lastName}`;

    return fullName1.toLowerCase() > fullName2.toLowerCase() ? 1 : -1
  });
}

export function searchUsers(searchString, users) {
  searchString = searchString.toLowerCase();

  return users.filter(user => {
    const firstName = user.firstName.toLowerCase();
    const lastName = user.lastName.toLowerCase();
    const uid = user.uid.toLowerCase();

    return firstName.indexOf(searchString) > -1 || lastName.indexOf(searchString) > -1 || uid.indexOf(searchString) > -1;
  });
}

export function isFuture(date) {
  return moment().diff(moment(date, DATE_FORMAT), 'days') < 0;
}

export function isToday(date) {
  return moment().isSame(moment(date, DATE_FORMAT), 'days');
}

export function mergeArrayObjectsByField(field, arrays) {
  let result = arrays[0];

  for (let i = 1; i < arrays.length; i++) {
    result = result.map(item1 => ({ ...item1, ...arrays[i].find(item2 => item2[field] === item1[field]) }));
  }

  return result;
}

export function sumArray(array) {
  return array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export function sumArrayByField(array, field) {
  return array.reduce((accumulator, currentValue) => accumulator + currentValue[field], 0);
}

export function vacationDays(vacations, dayOffs, currentMonth) {
  const year = moment(currentMonth).year();

  return vacations
      .reduce((accumulator, currentValue) => accumulator + vacationBusinessDays(currentValue, dayOffs, currentMonth, year), 0);
}

export function totalHours(user, dayOffs, currentMonth) {
  return sumArray(user.data) + user.sickDays.length * DAY_LENGTH + vacationDays(user.vacations, dayOffs, currentMonth) * DAY_LENGTH;
}

export function compensation(hours, maxHours, baseCompensation, exchangeRate) {
  return (exchangeRate || 1) * (hours * baseCompensation) / maxHours;
}

export function formatNumber(number) {
  return numbro(number).format({ thousandSeparated: true, mantissa: 2 });
}