import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import dateFns from 'date-fns';

import config from '../../config/firebase';
import { timeDataTable, dayOffsTable, userDataTable, vacationRequestsTable, userRolesTable, performanceReviewsTable, exchangeRateTable } from "../../config/tables";
import { VACATION_DAYS, SICK_DAYS, DATE_FORMAT, VACATION_STATUS, USER_STATUS } from "../../utils/constants";

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
  }

  doCreateUserWithEmailAndPassword = (email, password, userData) =>
      this.auth.createUserWithEmailAndPassword(email, password)
          .then(user =>
              this.saveUserData({...userData, vacationDays: VACATION_DAYS, sickDays: SICK_DAYS})
                  .then(() => this.doSendEmailVerification())
                  .then(() => user)
          );

  doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password).then(auth => auth.user);

  doSignOut = () => this.auth.signOut();

  doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  saveUserData = userData =>
      this.db.collection(userDataTable).doc(this.auth.currentUser.uid).set(userData)
          .then(() => this.auth.currentUser.updateProfile({displayName: `${userData.firstName} ${userData.lastName}`}));

  doGetCurrentUser = () => new Promise((resolve, reject) => {
    const unsubscribe = this.auth.onAuthStateChanged(user => {
      if (user) {
        Promise.all([this.getCurrentUserData(), this.getCurrentUserRoles()])
            .then(responses => resolve({...user, ...responses[0], ...responses[1]}))
      } else {
        resolve(user);
      }
      unsubscribe();
    }, reject);
  }).catch(() => null);

  getCurrentUserData = () =>
      this.db.collection(userDataTable).doc(this.auth.currentUser.uid).get()
          .then(doc => doc.data())
          .catch(() => {
          });

  getUserData = uid =>
      this.db.collection(userDataTable).doc(uid).get()
          .then(doc => doc.data())
          .catch(() => {
          });

  getCurrentUserRoles = () =>
      this.db.collection(userRolesTable).doc(this.auth.currentUser.uid).get()
          .then(doc => doc.data())
          .catch(() => {
          });

  saveTimeData = (data, sickDays, month, year, uid) => {
    uid = uid || this.auth.currentUser.uid;
    const docId = this.timeDataDocId(month, year, uid);

    return this.db.collection(timeDataTable).doc(docId).set({month, year, data, sickDays, uid});
  };

  fetchTimeData = (month, year, uid) => {
    const docId = this.timeDataDocId(month, year, uid);

    return this.db.collection(timeDataTable).doc(docId).get()
        .then(doc => doc.data())
        .then(data => ({...data, data: data.data || EMPTY_DATA.data, sickDays: data.sickDays || EMPTY_DATA.sickDays}))
        .catch(() => EMPTY_DATA);
  };

  timeDataDocId = (month, year, uid) => {
    uid = uid || this.auth.currentUser.uid;

    return `${uid}-${month}-${year}`;
  };

  fetchDayOffs = () =>
      this.db.collection(dayOffsTable).get()
          .then(snapshot => snapshot.docs.map(doc => doc.data()))
          .catch(() => []);

  saveSickDays = (sickDays, month, year) => {
    const docId = this.timeDataDocId(month, year);

    return this.db.collection(timeDataTable).doc(docId).update({sickDays});
  };

  fetchAllTimeData = (uid, year) => {
    year = year || dateFns.getYear(new Date());
    uid = uid || this.auth.currentUser.uid;

    return this.db.collection(timeDataTable)
        .where('uid', '==', uid)
        .where('year', '==', year)
        .get()
        .then(snapshot => snapshot.docs.map(doc => doc.data()))
        .catch(() => []);
  };

  submitVacationRequest = (vacationRequest, uid) => {
    uid = uid || this.auth.currentUser.uid;
    const createdDate = dateFns.format(new Date(), DATE_FORMAT);
    const year = vacationRequest.vacationStart.year();
    const startMonth = vacationRequest.vacationStart.month();
    const endMonth = vacationRequest.vacationEnd.month();

    return this.db.collection(vacationRequestsTable)
        .add({
          ...vacationRequest,
          vacationStart: vacationRequest.vacationStart.format(DATE_FORMAT),
          vacationEnd: vacationRequest.vacationEnd.format(DATE_FORMAT),
          ...VACATION_REQUEST,
          uid,
          createdDate,
          year,
          startMonth,
          endMonth
        });
  };

  fetchAllVacationRequests = (uid, year) => {
    year = year || dateFns.getYear(new Date());
    uid = uid || this.auth.currentUser.uid;

    return this.db.collection(vacationRequestsTable)
        .where('uid', '==', uid)
        .where('year', '==', year)
        .get()
        .then(snapshot => snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
        .catch(() => []);
  };

  fetchVacationRequests = (month, year, uid) => {
    uid = uid || this.auth.currentUser.uid;
    const promises = ['startMonth', 'endMonth'].map(
        field =>
            this.db.collection(vacationRequestsTable)
                .where('uid', '==', uid).where('status', '==', VACATION_STATUS.APPROVED).where(field, '==', month).where('year', '==', year)
                .get()
                .then(snapshot => snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
    );

    return Promise.all(promises)
        .then(responses => ([...responses[0], ...responses[1]]))
        .then(vacations => Array.from(new Set(vacations.map(v => v.id))).map(id => vacations.find(v => v.id === id)))
        .catch(() => []);
  };

  fetchAllUsersVacationRequests = (year) => {
    year = year || dateFns.getYear(new Date());

    return this.db.collection(vacationRequestsTable)
        .where('year', '==', year)
        .get()
        .then(snapshot => snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
        .catch(() => []);
  };

  deleteVacationRequest = docId => this.db.collection(vacationRequestsTable).doc(docId).delete();

  approveVacationRequest = docId =>
      this.db.collection(vacationRequestsTable).doc(docId).update({status: VACATION_STATUS.APPROVED});

  declineVacationRequest = (docId, declineReason) =>
      this.db.collection(vacationRequestsTable).doc(docId).update({status: VACATION_STATUS.DECLINED, declineReason});

  fetchUsers = status => {
    const collection = this.db.collection(userDataTable);

    if (status === USER_STATUS.ACTIVE) {
      return collection.get()
          .then(snapshot => snapshot.docs.filter(doc => !doc.data().disabled))
          .then(docs => docs.map(doc => ({...doc.data(), uid: doc.id})));
    }

    if (status === USER_STATUS.DISABLED) {
      return collection
          .where('disabled', '==', true).get()
          .then(snapshot => snapshot.docs.map(doc => ({...doc.data(), uid: doc.id})));
    }

    return collection.get()
        .then(snapshot => snapshot.docs.map(doc => ({...doc.data(), uid: doc.id})));
  };

  disableUser = uid => this.db.collection(userDataTable).doc(uid).update({disabled: true});

  enableUser = uid => this.db.collection(userDataTable).doc(uid).update({disabled: false});

  submitPerformanceReview = (uid, reviews) =>
      this.db.collection(performanceReviewsTable).doc(uid).set(reviews);

  fetchPerformanceReviews = uid =>
      this.db.collection(performanceReviewsTable).doc(uid).get()
          .then(doc => doc.data())
          .catch(() => {
          });

  fetchAllPerformanceReviews = users => {
    const PROMISES = users.map(user => this.fetchPerformanceReviews(user.uid));

    return Promise.all(PROMISES).then(
        values => values.map((value, index) => {
          let compensation, nextReview;

          if (!value) {
            compensation = 0;
            nextReview = null;
          } else {
            compensation = value.performanceReviews[value.performanceReviews.length - 1].compensation;
            nextReview = value.nextReview;
          }

          return {uid: users[index].uid, compensation, nextReview};
        })
    )
  };

  setExchangeRate = (exchangeRate, month, year) => this.db.collection(exchangeRateTable)
      .doc(`${month}-${year}`)
      .set({exchangeRate, month, year});

  fetchExchangeRate = (month, year) => this.db.collection(exchangeRateTable).doc(`${month}-${year}`).get()
      .then(doc => doc.data())
      .catch(() => {});
}

export default Firebase;

const EMPTY_DATA = {
  data: [],
  sickDays: []
};

const VACATION_REQUEST = {
  status: VACATION_STATUS.PENDING
};
