import React from 'react';
import dateFns from 'date-fns';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LoadingOverlay from 'react-loading-overlay';
import { MenuProvider } from 'react-contexify';

import CalendarContextMenu from './CalendarContextMenu';
import VacationModal from './VacationModal';
import MonthPicker from '../MonthPicker';
import { LAST_EDIT_DAY, MAX_DAILY_HOURS, CALENDAR_CONTEXT_ID, DATE_FORMAT } from '../../utils/constants';
import { isToday } from '../../utils/utils';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: this.props.defaultDate ? moment(this.props.defaultDate, DATE_FORMAT).toDate() : new Date(),
      invalidIndexes: [],
      vacationModal: false,
      vacationStart: null,
      unlocked: false
    };

    this.clear = this.clear.bind(this);
    this.submit = this.submit.bind(this);
    this.setCurrentMonth = this.setCurrentMonth.bind(this);
    this.switchLock = this.switchLock.bind(this);
    this.switchVacationModal = this.switchVacationModal.bind(this);
    this.handleVacationClick = this.handleVacationClick.bind(this);
  }

  componentDidMount() {
    const { currentMonth } = this.state;

    this.props.onMonthChange(dateFns.getMonth(currentMonth), dateFns.getYear(currentMonth));
  }

  handleInput(e, day) {
    const value = e.target.value;
    const timeData = [...this.props.timeData];

    if (!/^\d{0,2}[.]*\d{0,2}$/.test(value) || this.isCurrentMonthDisabled()) {
      return;
    }

    timeData[this.getDayIndex(day)] = value;

    this.props.onChange(timeData);
  }

  createInputHandler(day) {
    return function(e) {
      this.handleInput(e, day);
    }.bind(this);
  }

  clear() {
    this.props.onChange([]);
  }

  submit() {
    const currentMonth = this.state.currentMonth;
    const timeData = this.normalize(this.props.timeData);
    const invalidIndexes = [];

    timeData.forEach((value, index) => !this.validate(value) && (invalidIndexes[index] = true));
    this.setState({ invalidIndexes });

    if (invalidIndexes.length) {
      return;
    }

    this.props.onSubmit(timeData, dateFns.getMonth(currentMonth), dateFns.getYear(currentMonth));
  }

  getDayIndex(day) {
    return dateFns.getDate(day) - 1;
  }

  validate(value) {
    return value < MAX_DAILY_HOURS;
  }

  normalize(data) {
    return Array.from(data, value => value ? parseFloat(value) : 0).map(value => isNaN(value) ? 0 : value);
  }

  isDisabled(day) {
    const monthStart = dateFns.startOfMonth(this.state.currentMonth);

    return !dateFns.isSameMonth(day, monthStart);
  }

  isDayOff(day) {
    const dayOfWeek = dateFns.getDay(day);

    return dayOfWeek === 0 || dayOfWeek === 6;
  }

  isHoliday(day) {
    const date = dateFns.getDate(day);
    const month = dateFns.getMonth(day);

    return this.props.dayOffs.find(dayOff => dayOff.month === month && dayOff.day === date);
  }

  isSickDay(day) {
    const date = dateFns.getDate(day);
    const month = dateFns.getMonth(day);

    return this.props.sickDays.find(sickDay => sickDay === date) && month === dateFns.getMonth(this.state.currentMonth);
  }

  isVacation(date) {
    const day = moment(date, DATE_FORMAT);
    const vacations = this.props.vacations;

    for (let i = 0; i < vacations.length; i++) {
      const start = moment(vacations[i].vacationStart, DATE_FORMAT);
      const end = moment(vacations[i].vacationEnd, DATE_FORMAT);

      if (day.isBetween(start, end, 'day', '[]')) {
        return true;
      }
    }
  }

  totalHours() {
    return this.normalize(this.props.timeData).reduce((total, num) => total + num, 0);
  }

  isCurrentMonthDisabled() {
    const { currentMonth } = this.state;
    const today = new Date();

    if (this.state.unlocked) {
      return false;
    }

    if (this.props.locked) {
      return true;
    }

    if (dateFns.getYear(today) !== dateFns.getYear(currentMonth)) {
      return true;
    }

    if ((dateFns.getMonth(today) - 1) === dateFns.getMonth(currentMonth)) {
      if (dateFns.getDate(today) > LAST_EDIT_DAY) {
        return true;
      }
    } else if (dateFns.getMonth(today) !== dateFns.getMonth(currentMonth)) {
      return true;
    }
  }

  switchLock() {
    if (!this.props.canUnlock) {
      return;
    }

    this.setState({ unlocked: !this.state.unlocked });
  }

  renderHeader() {
    const dateFormat = 'MMM YYYY';

    return (
      <div className='d-flex flex-fill justify-content-between text-white font-secondary text-big p-2'>
        <div className='month-control' onClick={this.prevMonth}>
          <i className='fas fa-chevron-left'/>
        </div>
        <div className='month-control' onClick={() => this.setCurrentMonth(new Date())}>
          {dateFns.format(this.state.currentMonth, dateFormat)}
        </div>
        <div className='month-control' onClick={this.nextMonth}>
          <i className='fas fa-chevron-right'/>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = 'ddd';
    const days = [];

    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className='cal-cell' key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return (
      <div className='days cal-row font-secondary'>
        {days}
      </div>
    );
  }

  renderCells() {
    const { currentMonth } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const monthDisabled = this.isCurrentMonthDisabled();

    const rows = [];

    let days = [];
    let day = startDate;
    let tabIndex = 0;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const holiday = this.isHoliday(day);
        const isSickDay = this.isSickDay(day);
        const isVacation = this.isVacation(day);

        if (holiday || isSickDay || isVacation) {
          days.push(
            <OverlayTrigger key={day} overlay={
              <Tooltip>
                {holiday ? holiday.title : isSickDay ? 'Sick Day' : isVacation ? 'Vacation' : ''}
              </Tooltip>
            }>
              {this.renderCell(day, tabIndex, monthDisabled)}
            </OverlayTrigger>
          );
        } else {
          days.push(this.renderCell(day, tabIndex, monthDisabled));
        }
        day = dateFns.addDays(day, 1);
        tabIndex++;
      }
      rows.push(
        <div key={day} className='cal-row'>
          {days}
        </div>
      );
      days = [];
    }
    return (
      <div className='body'>{rows}</div>
    );
  }

  renderCell(day, tabIndex, monthDisabled) {
    const dateFormat = 'D';
    const { timeData } = this.props;
    const formattedDate = dateFns.format(day, dateFormat);
    const disabled = this.isDisabled(day);
    const invalid = this.state.invalidIndexes[this.getDayIndex(day)];
    const dayOff = this.isDayOff(day);
    const holiday = this.isHoliday(day);
    const isSickDay = this.isSickDay(day);
    const value = timeData[this.getDayIndex(day)] || '';
    const isVacation = this.isVacation(day);
    const isSickAllowed = (!dayOff && !disabled && !holiday && !isVacation) || this.props.canUnlock;
    const isTodayDate = isToday(day);

    return (
      <div
        key={day}
        className={`cal-cell border ${!isVacation && (disabled || dayOff) && 'bg-disabled'} ${holiday && 'bg-success'} ${isSickDay && 'progress-bar-striped bg-danger'} ${isVacation && 'progress-bar-striped bg-success'}`}>
        <span className={`date-number ${invalid && 'text-white'} ${isTodayDate && 'font-weight-bold text-success'}`}>{formattedDate}</span>
        {
          (disabled || isSickDay || holiday || isVacation) && (
            <input
              day={isSickAllowed ? day : null}
              type='text'
              disabled={disabled || isSickDay || holiday || isVacation}/>
          )
        }

        {
          !disabled && !isSickDay && !holiday && !isVacation && (
            <input
              day={isSickAllowed ? day : null}
              type='text'
              value={value}
              tabIndex={!dayOff ? tabIndex : -1}
              disabled={monthDisabled}
              className={invalid && 'bg-danger'}
              onChange={this.createInputHandler(day)}/>
          )
        }
      </div>
    );
  }

  nextMonth = () => {
    const nextMonth = dateFns.addMonths(this.state.currentMonth, 1);

    this.setCurrentMonth(nextMonth);
  };

  prevMonth = () => {
    const prevMonth = dateFns.subMonths(this.state.currentMonth, 1);

    this.setCurrentMonth(prevMonth);
  };

  setCurrentMonth = date => {
    this.props.onMonthChange(dateFns.getMonth(date), dateFns.getYear(date));
    this.setState({ currentMonth: date, unlocked: false });
    this.clear();
  };

  switchVacationModal() {
    this.setState({ vacationModal: !this.state.vacationModal });
  }

  handleVacationClick(vacationStart) {
    const { day, month, year } = vacationStart;
    this.switchVacationModal();

    this.setState({
      vacationStart: moment().date(day).month(month).year(year)
    });
  }

  render() {
    const isMonthDisabled = this.isCurrentMonthDisabled();
    const totalHours = this.totalHours();

    return (
      <div className='calendar d-flex flex-column bg-main'>

        <MonthPicker
          currentMonth={this.state.currentMonth}
          onPrevMonth={this.prevMonth}
          onNextMonth={this.nextMonth}
          onMonthClick={this.setCurrentMonth}/>

        <div className='shadow rounded bg-white p-3 d-flex flex-column align-items-center'>
          {this.renderDays()}
          <LoadingOverlay
            spinner
            active={this.props.loading}>
            <MenuProvider id={CALENDAR_CONTEXT_ID}>
              {this.renderCells()}
            </MenuProvider>
          </LoadingOverlay>
          <div className='btn-container pt-2 d-flex justify-content-between'>
            {
              this.state.unlocked && (
                <Button
                  disabled={!this.props.canUnlock}
                  variant='light'
                  className='font-weight-bold mr-2'
                  onClick={this.switchLock}>
                  <i className='fas fa-lock'/>
                </Button>
              )
            }
            {
              isMonthDisabled && (
                <Button
                  disabled={!this.props.canUnlock}
                  variant='light'
                  className='font-weight-bold flex-fill'
                  onClick={this.switchLock}>
                  <i className='fas fa-lock mr-2'/>
                  LOCKED
                </Button>
              )
            }

            {
              !isMonthDisabled && (
                <Button
                  variant='light'
                  className='font-weight-bold'
                  onClick={this.clear}>
                  CLEAR
                </Button>
              )
            }

            <Button
              disabled
              variant='light'
              className='flex-fill ml-2 mr-2'>
              <b>TOTAL:</b> {totalHours} hours
            </Button>

            {
              !isMonthDisabled && (
                <Button
                  className='font-weight-bold'
                  onClick={this.submit}>
                  SUBMIT
                </Button>
              )
            }
          </div>
        </div>

        <CalendarContextMenu
          onSickDay={this.props.onSickDay}
          onVacationClick={this.handleVacationClick}
          isMonthDisabled={isMonthDisabled}/>

        <VacationModal
          show={this.state.vacationModal}
          vacationStart={this.state.vacationStart}
          onHide={this.switchVacationModal}
          onVacationReuest={this.props.onVacationRequest}/>
      </div>
    );
  }
}

export default Calendar;
