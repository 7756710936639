import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import FormControl from 'react-bootstrap/FormControl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';
import dateFns from 'date-fns';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import PageHeader from '../components/PageHeader';
import MonthPicker from '../components/MonthPicker';
import ExchangeRateModal from '../components/ExchangeRateModal';
import { withFirebase } from '../components/Firebase';

import { USER_STATUS, DATE_FORMAT, DAY_LENGTH } from '../utils/constants';
import {
  monthBusinessDays,
  getDateFromQuery,
  sortUsers,
  searchUsers,
  sumArray,
  vacationDays,
  totalHours,
  mergeArrayObjectsByField,
  compensation,
  formatNumber,
  sumArrayByField
} from '../utils/utils';
import history from '../history';

class MonthReportPage extends React.Component {
  constructor(props) {
    super(props);

    const date = getDateFromQuery(this.props.location.search);

    this.state = {
      loading: false,
      currentMonth: date ? moment(date, DATE_FORMAT).toDate() : new Date(),
      users: [],
      daysOffs: [],
      filter: this.filters.ACTIVE,
      search: '',
      showCompensation: false,
      exchangeRate: 1,
      exchangeRateModal: false
    };

    this.prevMonth = this.prevMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.setCurrentMonth = this.setCurrentMonth.bind(this);
  }

  filters = {
    ...USER_STATUS,
    ALL: 'all'
  };

  componentDidMount() {
    const CURRENT_MONTH = this.state.currentMonth;

    this.setState({ loading: true });

    this.props.firebase.fetchExchangeRate(dateFns.getMonth(CURRENT_MONTH), dateFns.getYear(CURRENT_MONTH))
        .then(data => this.setState({ exchangeRate: data ? data.exchangeRate : 0 }));

    this.props.firebase.fetchDayOffs()
        .then(
            dayOffs => this.setState({ dayOffs }, () =>
                this.fetchData(this.state.currentMonth)
                    .then(users => this.setState({ users, loading: false })))
        );

    history.push(`/report?date=${moment(this.state.currentMonth).format(DATE_FORMAT)}`);
  }

  prevMonth() {
    const prevMonth = dateFns.subMonths(this.state.currentMonth, 1);

    this.setCurrentMonth(prevMonth);
  }

  nextMonth() {
    const nextMonth = dateFns.addMonths(this.state.currentMonth, 1);

    this.setCurrentMonth(nextMonth);
  }

  setCurrentMonth(currentMonth) {
    this.setState({ currentMonth });
    history.push(`/report?date=${moment(currentMonth).format(DATE_FORMAT)}`);
    this.handleMonthChange(currentMonth);
  }

  handleMonthChange(currentMonth) {
    this.setState({ loading: true });

    this.fetchData(currentMonth)
        .then(users => this.setState({ users, loading: false }));

    this.props.firebase.fetchExchangeRate(dateFns.getMonth(currentMonth), dateFns.getYear(currentMonth))
        .then(data => this.setState({ exchangeRate: data ? data.exchangeRate : 0 }));
  }

  fetchData(currentMonth) {
    return this.props.firebase.fetchUsers(this.state.filter)
        .then(users => {
          const PROMISES = [];

          PROMISES.push(this.props.firebase.fetchAllPerformanceReviews(users));
          PROMISES.push(this.fetchTimeData(users, this.state.currentMonth));
          PROMISES.push(this.fetchVacationData(users, this.state.currentMonth));

          return Promise.all(PROMISES)
              .then(results => {
                results.unshift(users);
                return mergeArrayObjectsByField('uid', results);
              });
        })
        .then(users => users.map(user => ({
          ...user,
          hoursWorked: sumArray(user.data),
          sickDayCount: user.sickDays.length,
          vacationDays: vacationDays(user.vacations, this.state.dayOffs, currentMonth),
          totalHours: totalHours(user, this.state.dayOffs, currentMonth)
        })))
        .then(users => users.map(user => ({
          ...user,
          compensation: parseFloat(user.compensation),
          monthCompensation: compensation(user.totalHours, this.monthBusinessDays() * DAY_LENGTH, user.compensation),
          uahCompensation: compensation(user.totalHours, this.monthBusinessDays() * DAY_LENGTH, user.compensation, this.state.exchangeRate)
        })));
  }

  fetchTimeData(users, currentMonth) {
    const PROMISES = users.map(({ uid }) => this.props.firebase.fetchTimeData(dateFns.getMonth(currentMonth), dateFns.getYear(currentMonth), uid));

    return Promise.all(PROMISES).then(
        timeDataArray =>
            timeDataArray.map((timeData, index) => ({
              uid: users[index].uid,
              ...timeData
            }))
    );
  }

  fetchVacationData(users, currentMonth) {
    const PROMISES = users.map(({ uid }) => this.props.firebase.fetchVacationRequests(dateFns.getMonth(currentMonth), dateFns.getYear(currentMonth), uid));

    return Promise.all(PROMISES).then(
        vacationItems => vacationItems.map((vacationData, index) => ({
          uid: users[index].uid,
          vacations: vacationData
        }))
    );
  }

  navigateToTimePage(uid, currentMonth) {
    currentMonth = moment(currentMonth).format(DATE_FORMAT);
    history.push(`/time/${uid}?date=${currentMonth}`);
  }

  monthBusinessDays() {
    return monthBusinessDays(this.state.currentMonth, this.state.dayOffs).length;
  }

  filterButtonVariant(filter) {
    const currentFilter = this.state.filter;

    return filter === currentFilter ? 'primary' : 'light';
  }

  changeFilter(filter) {
    this.setState({ filter, loading: true }, () =>
        this.fetchData(this.state.currentMonth)
            .then(users => this.setState({ users, loading: false }))
    )
  }

  switchExchangeRateModal() {
    this.setState({ exchangeRateModal: !this.state.exchangeRateModal });
  }

  clipboardText(user) {
    return `Compensation (USD): ${compensation(user.totalHours, this.monthBusinessDays() * DAY_LENGTH, user.compensation)}\nExchange Rate: ${this.state.exchangeRate}\nCompensation (UAH): ${compensation(user.totalHours, this.monthBusinessDays() * DAY_LENGTH, user.compensation, this.state.exchangeRate)}`;
  }

  renderRow(user) {
    const SHOW_COMPENSATION = this.state.showCompensation;

    return (
      <tr key={user.uid}>
        <td className='text-left'>
          {user.firstName} {user.lastName}
        </td>
        { !SHOW_COMPENSATION && <td>{user.hoursWorked}</td> }
        { !SHOW_COMPENSATION && <td>{user.sickDayCount}</td> }
        { !SHOW_COMPENSATION && <td>{user.vacationDays}</td> }

        <td>{this.monthBusinessDays() - user.sickDayCount - user.vacationDays}</td>
        <td>{user.totalHours}</td>

        {
          SHOW_COMPENSATION && (
              <td className='text-right text-monospace'>
                <span>${formatNumber(user.compensation)}</span>
              </td>
          )
        }
        {
          SHOW_COMPENSATION && (
              <td className='text-right text-monospace'>
                <span>${formatNumber(user.monthCompensation)}</span>
              </td>
          )
        }

        {
          SHOW_COMPENSATION && (
              <td className='text-right text-monospace'>
                <span>₴{formatNumber(user.uahCompensation)}</span>
              </td>
          )
        }
        <td>
          <Button variant='light' size='sm' className='mr-1' onClick={() => this.navigateToTimePage(user.uid, this.state.currentMonth)}>
            <i className='far fa-clock'/>
          </Button>
          {
            SHOW_COMPENSATION && (
                <CopyToClipboard text={this.clipboardText(user)} className='ml-1'>
                  <Button variant='primary' size='sm'>
                    <i className='far fa-copy'/>
                  </Button>
                </CopyToClipboard>
            )
          }
        </td>
      </tr>
    );
  }

  renderTotal() {
    return (
      <tr className='font-weight-bold'>
        <td colSpan={3} className='text-right'>TOTAL:</td>
        <td className='text-right'>
          ${formatNumber(sumArrayByField(this.state.users, 'compensation'))}
        </td>
        <td className='text-right'>
          ${formatNumber(sumArrayByField(this.state.users, 'monthCompensation'))}
        </td>
        <td className='text-right'>
          ₴{formatNumber(sumArrayByField(this.state.users, 'uahCompensation'))}
        </td>
        <td></td>
      </tr>
    );
  }

  render() {
    let { loading, users } = this.state;
    const SHOW_COMPENSATION = this.state.showCompensation;

    users = searchUsers(this.state.search, sortUsers(users));

    return (
      <div className='month-report-page d-flex flex-fill flex-column bg-main'>
        <PageHeader>
          REPORT
        </PageHeader>

        <div className='flex-fill d-flex align-items-center w-100 p-4 overflow-auto'>
          <LoadingOverlay
            active={loading}
            spinner
            className='w-100 d-flex flex-fill pt-2 pb-2 align-items-center flex-column max-height-100'>

            <div className='w-100'>
              <MonthPicker
                  currentMonth={this.state.currentMonth}
                  onPrevMonth={this.prevMonth}
                  onNextMonth={this.nextMonth}
                  onMonthClick={this.setCurrentMonth}/>
            </div>

            <div className='w-100 d-flex mb-3 justify-content-between'>
              <ButtonGroup className='shadow mr-1'>
                <Button
                  className='font-weight-bold'
                  variant={this.filterButtonVariant(this.filters.ACTIVE)}
                  onClick={() => this.changeFilter(this.filters.ACTIVE)}>
                  ACTIVE
                </Button>
                <Button
                  className='font-weight-bold'
                  variant={this.filterButtonVariant(this.filters.DISABLED)}
                  onClick={() => this.changeFilter(this.filters.DISABLED)}>
                  DISABLED
                </Button>
                <Button
                  className='font-weight-bold'
                  variant={this.filterButtonVariant(this.filters.ALL)}
                  onClick={() => this.changeFilter(this.filters.ALL)}>
                  ALL
                </Button>
              </ButtonGroup>

              <Button
                  onClick={() => this.setState({ showCompensation: !this.state.showCompensation })}
                  className='ml-1 mr-1 shadow'
                  variant='light'>
                {
                  SHOW_COMPENSATION ?
                      <i className='fas fa-eye-slash'/> :
                      <i className='fas fa-eye'/>
                }
              </Button>

              <FormControl
                  autoFocus
                  className='ml-2 mr-2 shadow'
                  placeholder='Search'
                  value={this.state.search}
                  onChange={e => this.setState({ search: e.target.value })}/>

              <div className='d-flex align-items-center'>
                <Badge variant='light' className='mr-1 shadow'>
                  MAX DAYS: <span className='font-weight-normal'>{this.monthBusinessDays()}</span>
                </Badge>
                <Badge variant='light' className='ml-1 mr-1 shadow'>
                  MAX HOURS: <span className='font-weight-normal'>{this.monthBusinessDays() * DAY_LENGTH}</span>
                </Badge>
                <Badge variant='light' className='ml-1 shadow cursor-pointer' onClick={() => this.switchExchangeRateModal()}>
                  EXCHANGE RATE: <span className='font-weight-normal'>{this.state.exchangeRate}</span>
                </Badge>
              </div>
            </div>
            <Table striped className='bg-white rounded shadow w-100 text-center'>
              <thead>
              <tr className='font-secondary'>
                <th className='text-left'>Name</th>
                { !SHOW_COMPENSATION && <th>Hours Worked</th> }
                { !SHOW_COMPENSATION && <th>Sick Days</th> }
                { !SHOW_COMPENSATION && <th>Vacation Days</th> }
                <th>Total Days</th>
                <th>Total Hours</th>
                { SHOW_COMPENSATION && <th>Base</th> }
                { SHOW_COMPENSATION && <th>USD</th> }
                { SHOW_COMPENSATION && <th>UAH</th> }
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {
                users.map(user => this.renderRow(user))
              }
              {
                SHOW_COMPENSATION && this.renderTotal()
              }
              </tbody>
            </Table>
          </LoadingOverlay>
        </div>

        <ExchangeRateModal
            show={this.state.exchangeRateModal}
            currentMonth={this.state.currentMonth}
            exchangeRate={this.state.exchangeRate}
            onSubmit={exchangeRate => this.setState({ exchangeRate })}
            onHide={() => this.switchExchangeRateModal()}/>
      </div>
    );
  }
}

export default withFirebase(MonthReportPage);
