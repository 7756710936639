import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { withFirebase } from '../Firebase';
import { setUser } from '../../actions/user';

class SignInForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: null,
      loading: false
    };

    this.signIn = this.signIn.bind(this);
  }

  signIn(e) {
    e.preventDefault();

    this.setState({ loading: true, error: null });
    return this.props.firebase.doSignInWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => this.props.firebase.doGetCurrentUser())
      .then(user => this.props.setUser(user))
      .catch(error => this.setState({ error: error.message }))
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const LOADING = this.state.loading;

    return (
      <div>
        <Form className='d-flex flex-column shadow p-3 rounded w-100 bg-white' onSubmit={this.signIn}>
          {
            this.state.error && (
              <Alert variant='danger'>{this.state.error}</Alert>
            )
          }
          <FormControl
            type='text'
            className='mb-2'
            placeholder='Email'
            disabled={LOADING}
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}/>
          <FormControl
            type='password'
            className='mb-2'
            placeholder='Password'
            disabled={LOADING}
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}/>
          <Button
            className='flex-fill font-weight-bold'
            type='submit'
            disabled={LOADING}
            onClick={this.signIn}>
            {
              !LOADING ? 'SIGN IN' : (
                <i className='fas fa-circle-notch fa-spin'/>
              )
            }
          </Button>
        </Form>
        <div className='pt-2 d-flex justify-content-between'>
          <Link to='/auth/sign-up' className='text-white'>
            Create account
          </Link>

          <Link to='/auth/reset' className='text-white'>
            Reset password
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(SignInForm));
