import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import LoadingOverlay from 'react-loading-overlay';

import PageHeader from '../components/PageHeader';
import { withFirebase } from '../components/Firebase';

import { setUser } from '../actions/user';

import { getUsedSickDays, getUsedVacationDays } from '../utils/utils';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      vacationDays: '',
      sickDays: '',
      loading: false,
      error: null,
      editable: false,
      changePasswordModal: false,
      passwordError: null,
      passwordLoading: false,
      password: '',
      passwordConfirm: '',
      usedSickDays: 0,
      usedVacationDays: 0
    };

    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.switchChangePasswordModal = this.switchChangePasswordModal.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount() {
    Promise.all(
      [this.fetchUser(), this.calculateUsedSickDays(), this.calculateUsedVacationDays()]
    )
    .then(() => this.setState({ loading: false }));
  }

  fetchUser() {
    this.setState({ loading: true });
    return this.props.firebase.doGetCurrentUser()
      .then(user => {
        this.props.setUser(user);
        this.setState({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          vacationDays: user.vacationDays,
          sickDays: user.sickDays
        })
      })
      .catch(error => this.setState({ error: error.message }))
      .finally(() => this.setState({ loading: false }));
  }

  calculateUsedSickDays() {
    getUsedSickDays(this.props.firebase)
      .then(usedSickDays => this.setState({ usedSickDays }));
  }

  calculateUsedVacationDays() {
    return this.props.firebase.fetchDayOffs()
      .then(dayOffs => getUsedVacationDays(this.props.firebase, dayOffs))
      .then(usedVacationDays => this.setState({ usedVacationDays }));
  }

  cancel() {
    this.fetchUser();
    this.setState({ editable: false });
  }

  save(e) {
    e.preventDefault();

    const { email, firstName, lastName, vacationDays, sickDays } = this.state;

    this.setState({ loading: true, error: null });
    this.props.firebase.saveUserData({ email, firstName, lastName, vacationDays, sickDays })
      .then(() => this.fetchUser())
      .catch(error => this.setState({ error: error.message }))
      .finally(() => this.setState({ loading: false }));
    this.setState({ editable: false });
  }

  switchChangePasswordModal() {
    this.setState({
      changePasswordModal: !this.state.changePasswordModal,
      password: '',
      passwordConfirm: '',
      passwordError: null
    });
  }

  changePassword(e) {
    e.preventDefault();

    const { password, passwordConfirm } = this.state;

    if (password !== passwordConfirm) {
      this.setState({ passwordError: 'Passwords should match' });
      return;
    }

    this.setState({ passwordLoading: true, passwordError: null });
    this.props.firebase.doPasswordUpdate(password)
      .then(() => this.switchChangePasswordModal())
      .catch(error => this.setState({ passwordError: error.message }))
      .finally(() => this.setState({ passwordLoading: false }));
  }

  render() {
    const LOADING = this.state.loading;
    const EDITABLE = this.state.editable;

    return (
      <div className='profile-page d-flex flex-fill flex-column h-100 bg-main align-items-center justify-content-center'>
        <PageHeader>
          PROFILE
        </PageHeader>

        <div className='flex-fill align-items-center d-flex flex-column justify-content-center'>
          <LoadingOverlay active={LOADING} spinner>
            <Form className='d-flex flex-column shadow p-3 rounded bg-white' onSubmit={this.save}>
              {
                this.state.error && (
                  <Alert variant='danger'>{this.state.error}</Alert>
                )
              }

              <FormControl
                disabled
                type='text'
                className='mb-2'
                placeholder='Email'
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}/>

              <div className='d-flex mb-2'>
                <FormControl
                  type='text'
                  className='mr-1'
                  placeholder='First Name'
                  disabled={LOADING || !EDITABLE}
                  value={this.state.firstName}
                  onChange={e => this.setState({ firstName: e.target.value })}/>

                <FormControl
                  type='text'
                  className='ml-1'
                  placeholder='Last Name'
                  disabled={LOADING || !EDITABLE}
                  value={this.state.lastName}
                  onChange={e => this.setState({ lastName: e.target.value })}/>
              </div>

              <div className='d-flex mb-2'>
                <Alert variant='success' className='flex-fill mr-1 w-50 text-center mb-0'>
                  Vacation days left
                  <div>{this.state.vacationDays - this.state.usedVacationDays}</div>
                </Alert>
                <Alert variant='success' className='flex-fill ml-1 w-50 text-center mb-0'>
                  Sick days left
                  <div>{this.state.sickDays - this.state.usedSickDays}</div>
                </Alert>
              </div>

              {
                !EDITABLE && (
                  <div className='d-flex'>
                    <Button
                      className='font-weight-bold flex-fill'
                      onClick={() => this.setState({ editable: true })}>
                      EDIT
                    </Button>
                  </div>
                )
              }

              {
                EDITABLE && (
                  <div className='d-flex'>
                    <Button
                      variant='light'
                      className='font-weight-bold flex-fill mr-1 w-50'
                      onClick={this.cancel}>
                      CANCEL
                    </Button>

                    <Button
                      type='submit'
                      className='font-weight-bold flex-fill ml-1 w-50'
                      onClick={this.save}>
                      SAVE
                    </Button>
                  </div>
                )
              }
            </Form>
          </LoadingOverlay>
          <div className='p-2'>
            <span className='cursor-pointer text-white' onClick={this.switchChangePasswordModal}>
              Change Password
            </span>
          </div>
        </div>

        <Modal show={this.state.changePasswordModal} onHide={this.switchChangePasswordModal}>
          <Form onSubmit={this.changePassword}>
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                this.state.passwordError && (
                  <Alert variant='danger'>{this.state.passwordError}</Alert>
                )
              }

              <FormControl
                className='mb-2'
                type='password'
                placeholder='Password'
                disabled={this.state.passwordLoading}
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}/>
              <FormControl
                type='password'
                placeholder='Confirm Password'
                disabled={this.state.passwordLoading}
                value={this.state.passwordConfirm}
                onChange={e => this.setState({ passwordConfirm: e.target.value })}/>
            </Modal.Body>
            <Modal.Footer>
              <div className='d-flex justify-content-between w-100'>
                <Button
                  variant='light'
                  className='font-weight-bold'
                  disabled={this.state.passwordLoading}
                  onClick={this.switchChangePasswordModal}>
                  CLOSE
                </Button>
                <Button
                  type='submit'
                  className='font-weight-bold'
                  disabled={this.state.passwordLoading}
                  onClick={this.changePassword}>
                  {
                    !this.state.passwordLoading ? 'SAVE' : (
                      <i className='fas fa-circle-notch fa-spin'/>
                    )
                  }
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(ProfilePage));
