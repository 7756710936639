import { SET_USER } from "../actions/actionTypes";

const INITIAL_STATE = {
  currentUser: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, currentUser: action.payload };
    default:
      return state;
  }
}
