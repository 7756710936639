import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import FormControl from 'react-bootstrap/FormControl';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import moment from 'moment';

import PageHeader from '../components/PageHeader';
import RoleAware from '../components/RoleAware';
import { withFirebase } from '../components/Firebase';

import { getDateFromQuery } from '../utils/utils';

import { DATE_FORMAT, VACATION_STATUS, ROLES } from '../utils/constants';

class VacationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      vacationRequests: [],
      filter: this.filters.MY,
      subFilter: VACATION_STATUS.PENDING,
      declineModal: false,
      declineReason: '',
      requestToDecline: null,
      modalError: null,
      search: ''
    };

    this.deleteVacationRequest = this.deleteVacationRequest.bind(this);
    this.switchDeclineModal = this.switchDeclineModal.bind(this);
    this.declineVacationRequest = this.declineVacationRequest.bind(this);
  }

  dateFormat = 'MMM D';

  filters = {
    MY: 'my',
    ALL: 'all'
  };

  componentDidMount() {
    this.fetchVacationRequests();
  }

  fetchVacationRequests() {
    let year = getDateFromQuery(this.props.location.search);
    year = year ? parseInt(year) : null;

    this.setState({ loading: true });
    (
      this.state.filter === this.filters.MY ?
        this.props.firebase.fetchAllVacationRequests(year) :
        this.fetchAllVacationRequests(year)
    ).then(vacationRequests => this.setState({ vacationRequests, loading: false }));
  }

  fetchAllVacationRequests(year) {
    return this.props.firebase.fetchAllUsersVacationRequests(year)
      .then(vacationRequests => {
        const PROMISES = vacationRequests.map(request => this.props.firebase.getUserData(request.uid));

        return Promise.all(PROMISES).then(data => data.map((user, index) => ({ user, ...vacationRequests[index] })))
      });
  }

  deleteVacationRequest(id) {
    this.setState({ loading: true });
    this.props.firebase.deleteVacationRequest(id)
      .then(() => this.fetchVacationRequests());
  }

  approveVacationRequest(id) {
    this.setState({ loading: true });
    this.props.firebase.approveVacationRequest(id)
    .then(() => this.fetchVacationRequests());
  }

  declineVacationRequest() {
    if (!this.state.declineReason) {
      this.setState({ modalError: 'Please provide decline reason' });
      return;
    }

    this.setState({ loading: true });
    this.switchDeclineModal();
    this.props.firebase.declineVacationRequest(this.state.requestToDecline.id, this.state.declineReason)
      .then(() => this.fetchVacationRequests());
  }

  renderRow(request) {
    const NOTES_POPOVER = (
      <Popover>{request.vacationNotes}</Popover>
    );

    const DECLINE_POPOVER = (
      <Popover>
        <b>Reason: </b>{request.declineReason}
      </Popover>
    );

    const USER = request.user || {};

    const isPast = moment(request.vacationEnd, DATE_FORMAT).isBefore(moment());

    return (
      <tr key={request.id} className={isPast ? 'disabled' : ''}>
        {
          this.state.filter === this.filters.ALL && (
            <td className='text-left'>
              {USER.firstName} {USER.lastName}
            </td>
          )
        }
        <td>{this.formatDate(request.createdDate)}</td>
        <td>{this.formatDate(request.vacationStart)}</td>
        <td>{this.formatDate(request.vacationEnd)}</td>
        <td>
          {
            request.status === VACATION_STATUS.APPROVED ?
              <Badge variant='success'>Approved</Badge> :
                request.status === VACATION_STATUS.PENDING ?
              <Badge variant='light'>Pending</Badge> :
                request.status === VACATION_STATUS.DECLINED ?
                  <OverlayTrigger overlay={DECLINE_POPOVER}>
                    <Badge variant='danger'>Declined</Badge>
                  </OverlayTrigger> : null
          }
        </td>
        <td>
          <OverlayTrigger overlay={NOTES_POPOVER}>
            <i className='fas fa-info-circle cursor-pointer color-main'/>
          </OverlayTrigger>
        </td>
        <td>
          {
            this.state.filter === this.filters.ALL && request.status === VACATION_STATUS.PENDING && (
              <Button
                variant='success'
                size='sm'
                className='mr-1'
                onClick={() => this.approveVacationRequest(request.id)}>
                <i className='fas fa-check'/>
              </Button>
            )
          }
          {
            this.state.filter === this.filters.ALL && request.status === VACATION_STATUS.PENDING && (
              <Button
                variant='danger'
                size='sm'
                className='mr-1'
                onClick={() => this.switchDeclineModal(request)}>
                <i className='fas fa-ban'/>
              </Button>
            )
          }
          <Button
            variant='danger'
            size='sm'
            disabled={request.status === VACATION_STATUS.APPROVED || request.status === VACATION_STATUS.DECLINED}
            onClick={() => this.deleteVacationRequest(request.id)}>
            <i className='far fa-trash-alt'/>
          </Button>
        </td>
      </tr>
    );
  }

  formatDate(date) {
    return moment(date, DATE_FORMAT).format(this.dateFormat);
  }

  filterButtonVariant(filter) {
    const currentFilter = this.state.filter;

    return filter === currentFilter ? 'primary' : 'light';
  }

  subFilterButtonVariant(filter) {
    const currentFilter = this.state.subFilter;

    return filter === currentFilter ? 'primary' : 'light';
  }

  changeFilter(filter) {
    this.setState({ filter }, () => this.fetchVacationRequests());
  }

  switchDeclineModal(requestToDecline = null) {
    this.setState({
      declineModal: !this.state.declineModal,
      declineReason: '',
      requestToDecline,
      modalError: ''
    });
  }

  filter(requests) {
    if (this.state.subFilter === 'all') {
      return requests;
    }

    return requests.filter(request => request.status === this.state.subFilter);
  }

  search(requests) {
    const search = this.state.search.toLowerCase();

    return requests.filter(request => {
      if (!request.user) {
        return 0;
      }

      const firstName = request.user.firstName.toLowerCase();
      const lastName = request.user.lastName.toLowerCase();

      return firstName.indexOf(search) > -1 || lastName.indexOf(search) > -1;
    });
  }

  sort(requests) {
    return requests.sort((r1, r2) => {
      const start1 = moment(r1.vacationStart, DATE_FORMAT);
      const start2 = moment(r2.vacationStart, DATE_FORMAT);

      return start1.isSameOrAfter(start2) ? -1 : 1
    });
  }

  render() {
    const LOADING = this.state.loading;
    const requestToDecline = this.state.requestToDecline ? this.state.requestToDecline : {};

    let vacationRequests =
      this.state.filter === this.filters.MY ? this.state.vacationRequests : this.search(this.filter(this.state.vacationRequests));

    vacationRequests = this.sort(vacationRequests);

    return (
      <div className='vacation-request-page d-flex flex-fill flex-column bg-main'>
        <PageHeader>
          VACATION REQUESTS
        </PageHeader>

        <div className='flex-fill d-flex align-items-center w-100 overflow-auto'>
          <LoadingOverlay
            active={LOADING}
            spinner
            className='w-100 d-flex flex-fill pt-2 pb-2 align-items-center flex-column max-height-100'>
            <RoleAware roles={[ROLES.ADMIN]}>
              <div className='mb-3 w-75 d-flex'>
                <ButtonGroup className='shadow'>
                  <Button
                    className='font-weight-bold'
                    variant={this.filterButtonVariant(this.filters.MY)}
                    onClick={() => this.changeFilter(this.filters.MY)}>
                    MY
                  </Button>
                  <Button
                    className='font-weight-bold'
                    variant={this.filterButtonVariant(this.filters.ALL)}
                    onClick={() => this.changeFilter(this.filters.ALL)}>
                    ALL
                  </Button>
                </ButtonGroup>

                {
                  this.state.filter === this.filters.ALL && (
                    <FormControl
                      autoFocus
                      className='ml-2 shadow'
                      placeholder='Search'
                      value={this.state.search}
                      onChange={e => this.setState({ search: e.target.value })}/>
                  )
                }
              </div>
            </RoleAware>
            {
              vacationRequests.length ? (
                <Table striped className='bg-white rounded shadow w-75 text-center'>
                  <thead>
                  <tr className='font-secondary'>
                    {
                      this.state.filter === this.filters.ALL && (
                        <th className='text-left'>Name</th>
                      )
                    }
                    <th>Created date</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Status</th>
                    <th>Notes</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    vacationRequests.map(request => this.renderRow(request))
                  }
                  </tbody>
                </Table>
              ) : null
            }
            {
              !vacationRequests.length ? (
                <div className='bg-white rounded shadow p-3 font-weight-bold font-secondary mb-4 w-75 text-center'>
                  NO VACATION REQUESTS
                </div>
              ) : null
            }
            {
              this.state.filter === this.filters.ALL && (
                <div className='w-75'>
                  <ButtonGroup className='w-100 shadow'>
                    <Button
                      className='font-weight-bold'
                      variant={this.subFilterButtonVariant(VACATION_STATUS.PENDING)}
                      onClick={() => this.setState({ subFilter: VACATION_STATUS.PENDING })}>
                      PENDING
                    </Button>
                    <Button
                      className='font-weight-bold'
                      variant={this.subFilterButtonVariant(VACATION_STATUS.APPROVED)}
                      onClick={() => this.setState({ subFilter: VACATION_STATUS.APPROVED })}>
                      APPROVED
                    </Button>
                    <Button
                      className='font-weight-bold'
                      variant={this.subFilterButtonVariant(VACATION_STATUS.DECLINED)}
                      onClick={() => this.setState({ subFilter: VACATION_STATUS.DECLINED })}>
                      DECLINED
                    </Button>
                    <Button
                      className='font-weight-bold'
                      variant={this.subFilterButtonVariant('all')}
                      onClick={() => this.setState({ subFilter: 'all' })}>
                      ALL
                    </Button>
                  </ButtonGroup>
                </div>
              )
            }
          </LoadingOverlay>
        </div>

        <Modal show={this.state.declineModal} onHide={() => this.switchDeclineModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Decline Vacation Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.state.modalError && (
                <Alert variant='danger'>
                  {this.state.modalError}
                </Alert>
              )
            }
            <Alert variant='secondary'>
              {requestToDecline.vacationStart} – {requestToDecline.vacationEnd}
            </Alert>
            <FormControl
              as='textarea'
              placeholder='Reason'
              value={this.state.declineReason}
              onChange={e => this.setState({ declineReason: e.target.value })}/>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-between w-100'>
              <Button
                variant='light'
                className='font-weight-bold'
                onClick={() => this.switchDeclineModal()}>
                CLOSE
              </Button>
              <Button
                className='font-weight-bold'
                onClick={this.declineVacationRequest}>
                SUBMIT
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withFirebase(VacationPage);
