import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import dateFns from 'date-fns';

import { withFirebase } from './Firebase';
import moment from "moment";
import { DATE_FORMAT, SECONDARY_DATE_FORMAT } from "../utils/constants";

class ExchangeRateModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            exchangeRate: '',
            loading: false
        };
    }

    submit() {
        const CURRENT_MONTH = this.props.currentMonth;
        const EXCHANGE_RATE = parseFloat(this.state.exchangeRate);

        this.setState({ loading: true });

        this.props.firebase.setExchangeRate(EXCHANGE_RATE, dateFns.getMonth(CURRENT_MONTH), dateFns.getYear(CURRENT_MONTH))
            .then(() => this.props.onSubmit(EXCHANGE_RATE))
            .then(() => this.props.onHide())
            .then(() => this.setState({ loading: false, exchangeRate: '' }));
    }

    render () {
        const DATE = moment(this.props.currentMonth, DATE_FORMAT).format(SECONDARY_DATE_FORMAT);
        const LOADING = this.state.loading;

        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Exchange Rate: {DATE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl
                        disabled={LOADING}
                        placeholder='Exchange Rate'
                        value={this.state.exchangeRate}
                        onChange={e => this.setState({ exchangeRate: e.target.value })}/>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                        <Button
                            disabled={LOADING}
                            variant='light'
                            className='font-weight-bold'
                            onClick={this.props.onHide}>
                            CLOSE
                        </Button>
                        <Button
                            disabled={LOADING}
                            className='font-weight-bold'
                            onClick={() => this.submit()}>
                            SUBMIT
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withFirebase(ExchangeRateModal);