import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { fullName } from '../../utils/utils';

export default class DisableUserModal extends React.Component {
    render () {
        const USER = this.props.user;

        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Disable User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to disable <b>{fullName(USER)}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                        <Button
                            variant='light'
                            className='font-weight-bold'
                            onClick={this.props.onHide}>
                            CLOSE
                        </Button>
                        <Button
                            className='font-weight-bold'
                            onClick={() => this.props.disableUser(USER)}>
                            CONTINUE
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}