import React from 'react';

import logoImg from '../static/logo.svg';

class AuthPage extends React.Component {
  render() {
    return (
      <div className='auth-page d-flex h-100 flex-column align-items-center justify-content-center bg-main'>
        <div className='max-width bg-main'>
          <img src={logoImg} alt='Sednor' className='bg-main pb-3 p-1 w-100' />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default AuthPage;
