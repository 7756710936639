import React from 'react';
import { Router, Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';
import history from './history';

import { setUser } from "./actions/user";

import AuthPage from './pages/AuthPage';
import TimePage from './pages/TimePage';
import ProfilePage from './pages/ProfilePage';
import VacationPage from './pages/VacationPage';
import UsersPage from './pages/UsersPage';
import MonthReportPage from './pages/MonthReportPage';
import { SignInForm, SignUpForm, ResetPasswordForm } from './components/auth';

import LoadingScreen from './components/LoadingScreen';
import Navigation from './components/Navigation';
import { withFirebase } from './components/Firebase';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pathname: history.location.pathname
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.doGetCurrentUser()
      .then(user => this.props.setUser(user))
      .then(() => this.setState({ loading: false }));

    history.listen(location => this.setState({ pathname: location.pathname }));
  }

  render() {
    const USER = this.props.user;

    if (this.state.loading) {
      return <LoadingScreen/>;
    }

    return (
      <div className='App'>
        <Router history={history}>
          {
            !USER && (
              <AuthPage>
                <Switch>
                  <Route exact path='/auth/sign-in' component={SignInForm}/>
                  <Route exact path='/auth/sign-up' component={SignUpForm}/>
                  <Route exact path='/auth/reset' component={ResetPasswordForm}/>
                  <Redirect from='*' to='/auth/sign-in'/>
                </Switch>
              </AuthPage>
            )
          }
          {
            USER && (
              <Navigation>
                <Switch>
                  <Route exact path='/time/:uid?' component={TimePage} key={this.state.pathname}/>
                  <Route exact path='/profile' component={ProfilePage}/>
                  <Route exact path='/vacation' component={VacationPage}/>
                  {
                    USER.admin && (
                      <Route exact path='/users' component={UsersPage}/>
                    )
                  }
                  {
                    USER.admin && (
                      <Route exact path='/report' component={MonthReportPage}/>
                    )
                  }
                  <Redirect from='*' to='/time'/>
                </Switch>
              </Navigation>
            )
          }
        </Router>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.user.currentUser
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(App));
