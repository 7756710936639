import React from 'react';
import dateFns from 'date-fns';

class MonthPicker extends React.Component {
    dateFormat = 'MMM YYYY';
    
    render() {
        return (
            <div className='d-flex justify-content-between text-white font-secondary text-big p-2 w-100'>
                <div className='month-control cursor-pointer' onClick={this.props.onPrevMonth}>
                    <i className='fas fa-chevron-left'/>
                </div>
                <div className='month-control cursor-pointer' onClick={() => this.props.onMonthClick(new Date())}>
                    {dateFns.format(this.props.currentMonth, this.dateFormat)}
                </div>
                <div className='month-control cursor-pointer' onClick={this.props.onNextMonth}>
                    <i className='fas fa-chevron-right'/>
                </div>
            </div>
        );
    }
}

export default MonthPicker;