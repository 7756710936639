import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';

import { withFirebase } from '../Firebase';

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: null,
      loading: false,
      sent: false
    };

    this.resetPassword = this.resetPassword.bind(this);
  }

  resetPassword(e) {
    e.preventDefault();

    this.setState({ loading: true, error: null });
    this.props.firebase.doPasswordReset(this.state.email)
      .then(() => this.setState({ sent: true }))
      .catch(error => this.setState({ error: error.message }))
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const LOADING = this.state.loading;
    const SENT = this.state.sent;

    return (
      <div>
        <Form className='d-flex flex-column shadow p-3 rounded w-100 bg-white' onSubmit={this.resetPassword}>
          {
            this.state.error && (
              <Alert variant='danger'>{this.state.error}</Alert>
            )
          }

          <FormControl
            type='text'
            className='mb-2'
            placeholder='Email'
            disabled={LOADING}
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}/>

          <Button
            className='flex-fill font-weight-bold'
            type='submit'
            disabled={LOADING || SENT}
            onClick={this.resetPassword}>
            {
              !LOADING && !SENT && 'RESET PASSWORD'
            }
            {
              LOADING && !SENT && (
                <i className="fas fa-circle-notch fa-spin"/>
              )
            }
            {
              !LOADING && SENT && 'Sent!'
            }
          </Button>
        </Form>
        <div className='pt-2 text-center'>
          <Link to='/auth/sign-in' className='text-white'>
            Back to sign in
          </Link>
        </div>
      </div>
    );
  }
}

export default withFirebase(ResetPasswordForm);
