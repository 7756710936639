import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import { withFirebase } from '../../components/Firebase';

import { fullName } from '../../utils/utils';
import {DATE_FORMAT, SECONDARY_DATE_FORMAT} from '../../utils/constants';

class PerformanceReviewModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prevCompensation: '-',
            newCompensation: '',
            date: moment(),
            nextDate: moment().add(6, 'M'),
            prevDate: null,
            scheduledNextDate: null,
            dateFocused: false,
            nextDateFocused: false,
            prevDateFocused: false,
            scheduledNextDateFocused: false,
            reviews: null,
            loading: false,
            showHistory: false
        };

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.props.firebase.fetchPerformanceReviews(this.props.user.uid)
            .then(reviews => {
                if (reviews) {
                    const prevReview = reviews.performanceReviews[reviews.performanceReviews.length - 1];

                    this.setState({
                        reviews,
                        prevDate: moment(prevReview.date, DATE_FORMAT),
                        scheduledNextDate: moment(reviews.nextReview, DATE_FORMAT),
                        prevCompensation: prevReview.compensation
                    });
                }
            });
    }

    submit () {
        const NEW_REVIEW = {
            date: this.state.date.format(DATE_FORMAT),
            compensation: this.state.newCompensation
        };
        let reviews;

        this.setState({ loading: true });

        if (!this.state.reviews) {
            reviews = {
                performanceReviews: [ NEW_REVIEW ],
                nextReview: this.state.nextDate.format(DATE_FORMAT)
            };
        } else {
            reviews = { ...this.state.reviews };
            reviews.performanceReviews.push(NEW_REVIEW);
            reviews.nextReview = this.state.nextDate.format(DATE_FORMAT);
        }

        this.props.firebase.submitPerformanceReview(this.props.user.uid, reviews)
            .then(() => {
                this.setState({ loading: false });
                this.props.onHide(null, true);
            });
    }

    handleDateChange(date) {
        this.setState({
            date,
            nextDate: moment(date).add(6, 'M')
        })
    }

    render () {
        const USER = this.props.user;

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Performance Review:
                        <div>{fullName(USER)}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex'>
                        <Form.Group className='flex-fill mr-1'>
                            <Form.Label className='font-weight-bold'>Previous Compensation:</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    disabled
                                    value={this.state.prevCompensation}/>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className='flex-fill ml-1'>
                            <Form.Label className='font-weight-bold'>New Compensation:</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    disabled={this.state.loading}
                                    value={this.state.newCompensation}
                                    onChange={e => this.setState({ newCompensation: e.target.value })}/>
                            </InputGroup>
                        </Form.Group>
                    </div>

                    <div className='d-flex'>
                        <div className='d-flex flex-column flex-fill'>
                            <Form.Label className='font-weight-bold'>Previous Review:</Form.Label>
                            <SingleDatePicker
                                disabled noBorder displayFormat={DATE_FORMAT}
                                date={this.state.prevDate}
                                onDateChange={prevDate => this.setState({ prevDate })}
                                focused={this.state.prevDateFocused}
                                onFocusChange={({ focused }) => this.setState({ prevDateFocused: focused })}
                                id='pr-prev-date-picker'/>
                        </div>

                        <div className='d-flex flex-column flex-fill'>
                            <Form.Label className='font-weight-bold'>Scheduled Next Review:</Form.Label>
                            <SingleDatePicker
                                disabled noBorder displayFormat={DATE_FORMAT}
                                date={this.state.scheduledNextDate}
                                onDateChange={scheduledNextDate => this.setState({ scheduledNextDate })}
                                focused={this.state.scheduledNextDateFocused}
                                onFocusChange={({ focused }) => this.setState({ scheduledNextDateFocused: focused })}
                                id='pr-scheduled-next-date-picker'/>
                        </div>

                        <div className='d-flex flex-column flex-fill'>
                            <Form.Label className='font-weight-bold'>Current Review Date:</Form.Label>
                            <SingleDatePicker
                                disabled={this.state.loading}
                                isOutsideRange={() => false}
                                noBorder displayFormat={DATE_FORMAT}
                                date={this.state.date}
                                onDateChange={date => this.handleDateChange(date)}
                                focused={this.state.dateFocused}
                                onFocusChange={({ focused }) => this.setState({ dateFocused: focused })}
                                id='pr-date-picker'/>
                        </div>

                        <div className='d-flex flex-column flex-fill'>
                            <Form.Label className='font-weight-bold'>Next Review:</Form.Label>
                            <SingleDatePicker
                                disabled={this.state.loading}
                                isOutsideRange={() => false}
                                noBorder displayFormat={DATE_FORMAT}
                                date={this.state.nextDate}
                                onDateChange={nextDate => this.setState({ nextDate })}
                                focused={this.state.nextDateFocused}
                                onFocusChange={({ focused }) => this.setState({ nextDateFocused: focused })}
                                id='pr-next-date-picker'/>
                        </div>
                    </div>

                    {
                        this.state.reviews && (
                            <div className='pt-4 pb-2 text-center color-main'>
                                <span
                                    className='cursor-pointer'
                                    onClick={() => this.setState({ showHistory: !this.state.showHistory })}>
                                    {
                                        !this.state.showHistory ? 'Show History' : 'Hide History'
                                    }
                                </span>
                            </div>
                        )
                    }

                    {
                        this.state.showHistory && (
                            <Table striped className='bg-white rounded shadow text-center'>
                                <thead>
                                <tr className='font-secondary'>
                                    <th>Date</th>
                                    <th>Compensation</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.reviews.performanceReviews.map(review => (
                                        <tr key={review.date}>
                                            <td>
                                                {moment(review.date, DATE_FORMAT).format(SECONDARY_DATE_FORMAT)}
                                            </td>
                                            <td>${review.compensation}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                        <Button
                            disabled={this.state.loading}
                            variant='light'
                            className='font-weight-bold'
                            onClick={this.props.onHide}>
                            CLOSE
                        </Button>
                        <Button
                            disabled={this.state.loading}
                            onClick={this.submit}
                            className='font-weight-bold'>
                            {
                                !this.state.loading ? 'SUBMIT' :
                                    <i className='fas fa-circle-notch fa-spin'/>
                            }
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withFirebase(PerformanceReviewModal);
