import React from 'react';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader';
import Calendar from '../components/Calendar';

import { withFirebase } from '../components/Firebase';

import { getDateFromQuery } from '../utils/utils';

class TimePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeData: [],
      dayOffs: [],
      sickDays: [],
      vacations: [],
      loading: false,
      userName: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSickDay = this.handleSickDay.bind(this);
    this.handleVacationRequest = this.handleVacationRequest.bind(this);
  }

  componentDidMount() {
    const uid = this.getUid();

    this.setState({ loading: true });
    this.props.firebase.fetchDayOffs()
      .then(dayOffs => this.setState({ dayOffs, loading: false }));

    if (uid) {
      this.props.firebase.getUserData(uid)
        .then(user => this.setState({ userName: `${user.firstName} ${user.lastName}` }));
    }
  }

  handleSubmit(data, month, year) {
    this.setState({ loading: true });
    this.props.firebase.saveTimeData(data, this.state.sickDays, month, year, this.getUid())
      .then(() => this.setState({ loading: false }));
  }

  handleMonthChange(month, year, isReload) {
    this.setState({ loading: true });

    if (!isReload) {
      this.setState({ timeData: [], sickDays: [], vacations: [] });
    }

    const uid = this.getUid();

    const vacationsPromise = this.props.firebase.fetchVacationRequests(month, year, uid)
      .then(vacations => this.setState({ vacations }));
    const timeDataPromise = this.props.firebase.fetchTimeData(month, year, uid)
      .then(timeData => this.setState({
        timeData: timeData ? timeData.data : [],
        sickDays: timeData ? timeData.sickDays : []
      }));

    return Promise.all([timeDataPromise, vacationsPromise])
      .then(() => this.setState({ loading: false }));
  }

  handleSickDay(sickDay) {
    const data = [ ...this.state.timeData ];
    let sickDays = [ ...this.state.sickDays ];

    if (sickDays.find(day => day === sickDay.day)) {
      sickDays = sickDays.filter(day => day !== sickDay.day);
    } else {
      sickDays.push(sickDay.day);
    }

    if (data[sickDay.day - 1]) {
      data[sickDay.day - 1] = 0;
    }

    this.setState({ loading: true });
    this.props.firebase.saveTimeData(data, sickDays, sickDay.month, sickDay.year, this.getUid())
      .then(() => this.handleMonthChange(sickDay.month, sickDay.year, true))
      .finally(() => this.setState({ loading: false }));
  }

  handleVacationRequest(vacationRequest) {
    return this.props.firebase.submitVacationRequest(vacationRequest, this.getUid());
  }

  handleChange(timeData) {
    this.setState({ timeData });
  }

  getUid() {
    return this.props.match.params.uid || null;
  }

  render() {
    const { userName } = this.state;
    const USER = this.props.user;
    const defaultDate = getDateFromQuery(this.props.location.search);

    return (
      <div className='d-flex flex-fill flex-column h-100 bg-main align-items-center justify-content-center'>
        <PageHeader>
          {
            !userName ? 'TIME TRACKING' : `TIME TRACKING: ${userName}`
          }
        </PageHeader>
        <div className='flex-fill align-items-center d-flex'>
          <Calendar
            defaultDate={defaultDate}
            timeData={this.state.timeData}
            dayOffs={this.state.dayOffs}
            sickDays={this.state.sickDays}
            vacations={this.state.vacations}
            loading={this.state.loading}
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            onMonthChange={this.handleMonthChange}
            onSickDay={this.handleSickDay}
            onVacationRequest={this.handleVacationRequest}
            locked={this.getUid()}
            canUnlock={USER.admin}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.currentUser
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(TimePage));
