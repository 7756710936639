import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import Button from 'react-bootstrap/Button';

import RoleAware from './RoleAware';

import { withFirebase } from './Firebase';
import { setUser } from '../actions/user';
import { getInitials } from '../utils/utils';
import { ROLES } from '../utils/constants';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sent: false,
      loading: false
    };

    this.signOut = this.signOut.bind(this);
    this.resendLink = this.resendLink.bind(this);
  }

  signOut() {
    this.props.firebase.doSignOut()
      .then(() => this.props.setUser(null));
  }

  resendLink() {
    this.setState({ loading: true });
    this.props.firebase.doSendEmailVerification()
      .then(() => this.setState({ sent: true, loading: false }));
  }

  renderOverlayText() {
    const LOADING = this.state.loading;
    const SENT = this.state.sent;

    if (this.props.user.disabled) {
      return (
        <div>
          <div className='text-big font-weight-bold font-secondary mb-2'>
            Your account has been disabled
          </div>
          <i className='far fa-sad-tear overlay-icon'/>
        </div>
      );
    }

    return (
      <div>
        <div className='text-big font-weight-bold font-secondary mb-2'>
          Please verify your email address
        </div>
        <Button
          variant='light'
          disabled={this.state.sent || LOADING}
          onClick={this.resendLink}>
          {
            LOADING && (
              <i className='fas fa-circle-notch fa-spin'/>
            )
          }
          {
            !LOADING && !SENT && 'Re-send Link'
          }
          {
            !LOADING && SENT && 'Sent!'
          }
        </Button>
      </div>
    );
  }

  render() {
    const USER = this.props.user;

    return (
      <div className='navigation d-flex flex-fill h-100'>
        <div className='side-nav d-flex shadow flex-column align-items-center'>
          <div className='flex-fill w-100 d-flex flex-column align-items-center'>
            <NavLink to='/profile' className='profile-link pt-2 pb-2 w-100 d-flex justify-content-center'>
              <div className='bg-main rounded-circle text-white font-secondary font-weight-bold d-flex align-items-center justify-content-center'>
                {getInitials(USER)}
              </div>
            </NavLink>
            <NavLink to='/time' className='icon-link mt-4 p-2 w-100 d-flex justify-content-center'>
              <i className='far fa-clock'/>
            </NavLink>

            <NavLink to='/vacation' className='icon-link mt-4 p-2 w-100 d-flex justify-content-center'>
              <i className='fas fa-plane-departure'/>
            </NavLink>

            <RoleAware roles={[ROLES.ADMIN]}>
              <NavLink to='/users' className='icon-link mt-4 p-2 w-100 d-flex justify-content-center'>
                <i className='fas fa-users'/>
              </NavLink>
            </RoleAware>

            <RoleAware roles={[ROLES.ADMIN]}>
              <NavLink to='/report' className='icon-link mt-4 p-2 w-100 d-flex justify-content-center'>
                <i className='far fa-calendar-alt'/>
                {/*<i className='fas fa-user-clock'/>*/}
              </NavLink>
            </RoleAware>
          </div>
          <div className='p-2 cursor-pointer' onClick={this.signOut}>
            <i className='fas fa-sign-out-alt text-danger'/>
          </div>
        </div>
        <div className='d-flex flex-fill'>
          <LoadingOverlay
            active={!this.props.user.emailVerified || this.props.user.disabled}
            text={this.renderOverlayText()}
            className='d-flex flex-fill'>
            {this.props.children}
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.currentUser
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(Navigation));
