import React from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DATE_FORMAT, MIN_VACATION_REQUEST_DIFF, MAX_VACATION_LENGTH } from "../../utils/constants";

class VacationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vacationStart: null,
      vacationEnd: null,
      focusedInput: null,
      vacationNotes: '',
      error: null,
      loading: false,
      success: false
    };

    this.hide = this.hide.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleShow() {
    this.setState({
      vacationStart: this.props.vacationStart
    })
  }

  hide() {
    this.setState({
      vacationStart: null,
      vacationEnd: null,
      focusedInput: null,
      vacationNotes: '',
      error: '',
      loading: false,
      success: false
    });
    this.props.onHide();
  }

  handleSubmit() {
    const { vacationStart, vacationEnd, vacationNotes } = this.state;

    if (!vacationStart || !vacationEnd) {
      this.setState({ error: 'Please specify start and end dates' });
      return;
    }

    if (!vacationNotes) {
      this.setState({ error: 'Please add a note to your request' });
      return;
    }

    this.setState({ loading: true, error: null });
    this.props.onVacationReuest({
      vacationStart: vacationStart,
      vacationEnd: vacationEnd,
      vacationNotes: vacationNotes
    })
    .then(() => this.setState({ success: true }))
    .catch(error => this.setState({ error: error.message }))
    .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { vacationStart, vacationEnd, loading, success } = this.state;
    const isTooEarly = vacationStart ? vacationStart.diff(moment(), 'days') < MIN_VACATION_REQUEST_DIFF : false;
    const isTooLong = vacationStart && vacationEnd ? vacationEnd.diff(vacationStart, 'days') > MAX_VACATION_LENGTH : false;

    return (
      <Modal show={this.props.show} onHide={this.hide} onShow={this.handleShow}>
        <Modal.Header closeButton>
          <Modal.Title>Request Vacation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            success && (
              <Alert variant='success'>
                Request has been sent. Please wait for approval
              </Alert>
            )
          }
          {
            isTooEarly && (
              <Alert variant='warning'>
                <b>Warning!</b> Vacation start date is less than 2 weeks from now
              </Alert>
            )
          }
          {
            isTooLong && (
              <Alert variant='warning'>
                <b>Warning!</b> Vacation length is more than 2 weeks
              </Alert>
            )
          }
          {
            this.state.error && (
              <Alert variant='danger'>{this.state.error}</Alert>
            )
          }
          <div className='mb-2'>
            <DateRangePicker
              noBorder
              minimumNights={0}
              isOutsideRange={this.props.user.admin ? () => false : day => !isInclusivelyAfterDay(day, moment())}
              startDateId='vacation-start-date'
              endDateId='vacation-end-date'
              startDate={this.state.vacationStart}
              endDate={this.state.vacationEnd}
              focusedInput={this.state.focusedInput}
              onDatesChange={({ startDate, endDate }) => this.setState({
                vacationStart: startDate,
                vacationEnd: endDate
              })}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              displayFormat={DATE_FORMAT}
              disabled={loading || success}/>
          </div>
          <FormControl
            as='textarea'
            placeholder='Notes'
            disabled={loading || success}
            value={this.state.vacationNotes}
            onChange={e => this.setState({ vacationNotes: e.target.value })}/>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-between w-100'>
            <Button
              variant='light'
              className='font-weight-bold'
              disabled={loading}
              onClick={this.hide}>
              CLOSE
            </Button>
            {
              !success && (
                <Button
                  className='font-weight-bold'
                  disabled={loading || success}
                  onClick={this.handleSubmit}>
                  {
                    !loading ? 'SUBMIT' : (
                      <i className='fas fa-circle-notch fa-spin'/>
                    )
                  }
                </Button>
              )
            }
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.currentUser
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VacationModal);
