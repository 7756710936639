import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';
import FormControl from 'react-bootstrap/FormControl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

import PageHeader from '../components/PageHeader';
import { DisableUserModal, PerformanceReviewModal } from '../components/User';
import { withFirebase } from '../components/Firebase';

import { DATE_FORMAT, SECONDARY_DATE_FORMAT, USER_STATUS } from '../utils/constants';
import {
  getUsedSickDays,
  getUsedVacationDays,
  sortUsers,
  searchUsers,
  mergeArrayObjectsByField,
  getDateFromQuery
} from '../utils/utils';
import history from '../history';

class UsersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      loading: false,
      disableModal: false,
      performanceReviewModal: false,
      selectedUser: null,
      filter: this.filters.ACTIVE,
      search: '',
      showCompensation: false
    };

    this.disableUser = this.disableUser.bind(this);
    this.switchDisableModal = this.switchDisableModal.bind(this);
    this.switchPerformanceReviewModal = this.switchPerformanceReviewModal.bind(this);
  }

  filters = {
    ...USER_STATUS,
    ALL: 'all'
  };

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData() {
    this.fetchUsers()
    .then(users => this.fetchUsedSickDays(users))
    .then(users => this.fetchUsedVacationDays(users))
    .then(users => this.fetchPerformanceReviews(users));
  }

  fetchUsers() {
    this.setState({ loading: true });
    return this.props.firebase.fetchUsers()
      .then(users => {
        users = users.filter(user => this.userFilter(user));
        this.setState({ users, loading: false });
        return users;
      });
  }

  fetchUsedSickDays(users) {
    const year = getDateFromQuery(this.props.location.search);
    const PROMISES = users.map(user => getUsedSickDays(this.props.firebase, user.uid, year));

    return Promise.all(PROMISES).then(
      values => values.map((value, index) => ({ ...users[index], usedSickDays: value }))
    )
    .then(users => {
      this.setState({ users, loading: false });
      return users;
    });
  }

  fetchUsedVacationDays(users) {
    const year = getDateFromQuery(this.props.location.search);

    return this.props.firebase.fetchDayOffs()
      .then(dayOffs => {
        const PROMISES = users.map(user => getUsedVacationDays(this.props.firebase, dayOffs, user.uid, year));

        return Promise.all(PROMISES).then(
          values => values.map((value, index) => ({ ...users[index], usedVacationDays: value }))
        )
        .then(users => {
          this.setState({ users, loading: false });
          return users;
        });
      });
  }

  fetchPerformanceReviews(users) {
    return this.props.firebase.fetchAllPerformanceReviews(users)
    .then(performanceReviews => {
      users = mergeArrayObjectsByField('uid', [users, performanceReviews]);
      this.setState({ users, loading: false });
      return users;
    });
  }

  disableUser(user) {
    this.setState({ loading: true });
    this.props.firebase.disableUser(user.uid)
      .then(() => this.switchDisableModal())
      .then(() => this.fetchUsers())
      .then(() => this.setState({ loading: false }));
  }

  enableUser(user) {
    this.setState({ loading: true });
    this.props.firebase.enableUser(user.uid)
    .then(() => this.fetchUsers())
    .then(() => this.setState({ loading: false }));
  }

  renderUID(uid) {
    return `${uid.slice(0, 6)}...`;
  }

  renderRow(user) {
    return (
      <tr key={user.uid}>
        <td className='text-left'>
          {user.firstName} {user.lastName}
        </td>
        <td className='text-monospace text-small'>
          <CopyToClipboard text={user.uid}>
            <span className='cursor-pointer'>{this.renderUID(user.uid)}</span>
          </CopyToClipboard>
        </td>
        <td>
          {user.usedVacationDays ? user.vacationDays - user.usedVacationDays : user.vacationDays}
        </td>
        <td>
          {user.usedSickDays ? user.sickDays - user.usedSickDays : user.sickDays}
        </td>
        <td>{user.nextReview ? moment(user.nextReview, DATE_FORMAT).format(SECONDARY_DATE_FORMAT) : '-'}</td>
        <td className={`${!this.state.showCompensation && 'show-on-hover'} text-right text-monospace`}>
          <span>${user.compensation || '–'}</span>
        </td>
        <td>
          <Button variant='light' size='sm' className='mr-1' onClick={() => this.switchPerformanceReviewModal(user)}>
            <i className='far fa-money-bill-alt'/>
          </Button>
          <Button variant='light' size='sm' className='mr-1' onClick={() => this.navigateToTimePage(user.uid)}>
            <i className='far fa-clock'/>
          </Button>
          {
            user.disabled ? (
              <Button variant='success' size='sm' onClick={() => this.enableUser(user)}>
                <i className='fas fa-redo-alt'/>
              </Button>
            ) : (
              <Button variant='danger' size='sm' onClick={() => this.switchDisableModal(user)}>
                <i className='fas fa-ban'/>
              </Button>
            )
          }
        </td>
      </tr>
    )
  }

  navigateToTimePage(uid) {
    history.push(`/time/${uid}`);
  }

  switchDisableModal(user = null) {
    this.setState({
      disableModal: !this.state.disableModal,
      selectedUser: user
    });
  }

  switchPerformanceReviewModal(user = null, reloadData) {
    if (reloadData) {
      this.fetchUserData();
    }

    this.setState({
      performanceReviewModal: !this.state.performanceReviewModal,
      selectedUser: user
    });
  }

  filterButtonVariant(filter) {
    const currentFilter = this.state.filter;

    return filter === currentFilter ? 'primary' : 'light';
  }

  changeFilter(filter) {
    this.setState({ filter }, () => this.fetchUserData());
  }

  userFilter(user) {
    if (this.state.filter === this.filters.ACTIVE) {
      return !user.disabled;
    }

    if (this.state.filter === this.filters.DISABLED) {
      return user.disabled;
    }

    return true;
  }

  render() {
    let { users, loading, selectedUser } = this.state;

    users = searchUsers(this.state.search, sortUsers(users));

    return (
      <div className='users-page d-flex flex-fill flex-column bg-main'>
        <PageHeader>
          USERS
        </PageHeader>

        <div className='flex-fill d-flex align-items-center w-100 overflow-auto'>
          <LoadingOverlay
            active={loading}
            spinner
            className='w-100 d-flex flex-fill pt-2 pb-2 align-items-center flex-column max-height-100'>
            <div className='mb-3 w-75 d-flex justify-content-between'>
              <ButtonGroup className='shadow'>
                <Button
                  className='font-weight-bold'
                  variant={this.filterButtonVariant(this.filters.ACTIVE)}
                  onClick={() => this.changeFilter(this.filters.ACTIVE)}>
                  ACTIVE
                </Button>
                <Button
                  className='font-weight-bold'
                  variant={this.filterButtonVariant(this.filters.DISABLED)}
                  onClick={() => this.changeFilter(this.filters.DISABLED)}>
                  DISABLED
                </Button>
                <Button
                  className='font-weight-bold'
                  variant={this.filterButtonVariant(this.filters.ALL)}
                  onClick={() => this.changeFilter(this.filters.ALL)}>
                  ALL
                </Button>
              </ButtonGroup>

              <Button
                  onClick={() => this.setState({ showCompensation: !this.state.showCompensation })}
                  className='ml-2 shadow'
                  variant='light'>
                {
                  this.state.showCompensation ?
                      <i className='fas fa-eye-slash'/> :
                      <i className='fas fa-eye'/>
                }
              </Button>

              <FormControl
                  autoFocus
                  className='ml-2 mr-2 shadow'
                  placeholder='Search'
                  value={this.state.search}
                  onChange={e => this.setState({ search: e.target.value })}/>

              <div className='d-flex align-items-center'>
                <Badge variant='light' className='mr-1 shadow'>
                  TOTAL USERS: <span className='font-weight-normal'>{users.length}</span>
                </Badge>
              </div>
            </div>
            <Table striped className='bg-white rounded shadow w-75 text-center'>
              <thead>
              <tr className='font-secondary'>
                <th className='text-left'>Name</th>
                <th>UID</th>
                <th>Vacations left</th>
                <th>Sick days left</th>
                <th>Next Review</th>
                <th className='text-right'>Compens.</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {
                users.map(user => this.renderRow(user))
              }
              </tbody>
            </Table>
          </LoadingOverlay>
        </div>

        <DisableUserModal
          user={selectedUser}
          show={this.state.disableModal}
          onHide={this.switchDisableModal}
          disableUser={this.disableUser}/>

        {
          selectedUser &&
          <PerformanceReviewModal
              user={selectedUser}
              show={this.state.performanceReviewModal}
              onHide={this.switchPerformanceReviewModal}/>
        }
      </div>
    );
  }
}

export default withFirebase(UsersPage);
