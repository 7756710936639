export const LAST_EDIT_DAY = 10;
export const MAX_DAILY_HOURS = 24;

export const VACATION_DAYS = 20;
export const SICK_DAYS = 7;

export const CALENDAR_CONTEXT_ID = 'calendar-context-menu';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const SECONDARY_DATE_FORMAT = 'MMM YYYY';

export const MIN_VACATION_REQUEST_DIFF = 13;
export const MAX_VACATION_LENGTH = 12;

export const VACATION_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined'
};

export const ROLES = {
  ADMIN: 'admin'
};

export const USER_STATUS = {
  ACTIVE: 'active',
  DISABLED: 'disabled'
};

export const DAY_LENGTH = 8;
