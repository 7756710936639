import React from 'react';

class PageHeader extends React.Component {
  render() {
    return (
      <div className='font-secondary text-white w-100 pt-2 pl-3 text-big'>
        {this.props.children}
      </div>
    );
  }
}

export default PageHeader;
