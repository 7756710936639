import React from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import { Menu, Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import { CALENDAR_CONTEXT_ID, DATE_FORMAT } from '../../utils/constants';
import { isFuture } from '../../utils/utils';
import { withFirebase } from '../Firebase';

class CalendarContextMenu extends React.Component {
  constructor(props) {
    super(props);

    this.requestSickDay = this.requestSickDay.bind(this);
    this.requestVacation = this.requestVacation.bind(this);
  }

  requestSickDay({ event }) {
    const DAY = this.parseDate(event);

    if (!DAY || this.props.isMonthDisabled || (isFuture(dateFns.format(DAY.date, DATE_FORMAT)) && !this.props.user.admin)) {
      return;
    }

    this.props.onSickDay(DAY);
  }

  requestVacation({ event }) {
    const DAY = this.parseDate(event);
    const thisMonth = dateFns.getMonth(new Date());

    if (!DAY || (thisMonth > DAY.month && !this.props.user.admin)) {
      return;
    }

    this.props.onVacationClick(DAY);
  }

  parseDate(event) {
    const DAY = event.target.attributes.day ? event.target.attributes.day.value : null;

    if (!DAY) {
      return null;
    }

    return {
      day: dateFns.getDate(DAY),
      month: dateFns.getMonth(DAY),
      year: dateFns.getYear(DAY),
      date: DAY
    };
  }

  render() {
    return (
      <Menu id={CALENDAR_CONTEXT_ID} className='calendar-context-menu'>
        <Item onClick={this.requestSickDay}>
          <div className='icon-container mr-2'>
            <i className='fas fa-thermometer-three-quarters'/>
          </div>
          Sick Day
        </Item>
        <Item onClick={this.requestVacation}>
          <div className='icon-container mr-2'>
            <i className='fas fa-plane-departure'/>
          </div>
          Request Vacation
        </Item>
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.currentUser
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(CalendarContextMenu));
