import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { withFirebase } from '../Firebase';
import { setUser } from '../../actions/user';

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
      error: null,
      loading: false
    };

    this.signUp = this.signUp.bind(this);
  }

  signUp(e) {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    const { firstName, lastName, email } = this.state;

    this.setState({ loading: true, error: null });
    this.props.firebase.doCreateUserWithEmailAndPassword(this.state.email, this.state.password, { firstName, lastName, email })
      .then(() => this.props.firebase.doGetCurrentUser())
      .then(user => this.props.setUser(user))
      .catch(error => this.setState({ error: error.message }))
      .finally(() => this.setState({ loading: false }));
  }

  validate() {
    if (!this.state.email.includes('@sednor.net')) {
      this.setState({ error: 'You must use corporate email address (@sednor.net)' });
      return false;
    }

    if (!this.state.firstName || !this.state.lastName) {
      this.setState({ error: 'Please fill all the fields' });
      return false;
    }

    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ error: 'Passwords should match' });
      return false;
    }

    return true;
  }

  render() {
    const LOADING = this.state.loading;

    return (
      <div>
        <Form className='d-flex flex-column shadow p-3 rounded w-100 bg-white' onSubmit={this.signUp}>
          {
            this.state.error && (
              <Alert variant='danger'>{this.state.error}</Alert>
            )
          }

          <FormControl
            type='text'
            className='mb-2'
            placeholder='Email'
            disabled={LOADING}
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}/>

          <FormControl
            type='text'
            className='mb-2'
            placeholder='First Name'
            disabled={LOADING}
            value={this.state.firstName}
            onChange={e => this.setState({ firstName: e.target.value })}/>
          <FormControl
            type='text'
            className='mb-2'
            placeholder='Last Name'
            disabled={LOADING}
            value={this.state.lastName}
            onChange={e => this.setState({ lastName: e.target.value })}/>

          <FormControl
            type='password'
            className='mb-2'
            placeholder='Password'
            disabled={LOADING}
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}/>

          <FormControl
            type='password'
            className='mb-2'
            placeholder='Confirm Password'
            disabled={LOADING}
            value={this.state.passwordConfirm}
            onChange={e => this.setState({ passwordConfirm: e.target.value })}/>

          <Button
            className='flex-fill font-weight-bold'
            type='submit'
            disabled={LOADING}
            onClick={this.signUp}>
            {
              !LOADING ? 'SIGN UP' : (
                <i className='fas fa-circle-notch fa-spin'/>
              )
            }
          </Button>
        </Form>
        <div className='pt-2 text-center'>
          <Link to='/auth/sign-in' className='text-white'>
            Back to sign in
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(SignUpForm));
