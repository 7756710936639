import React from 'react';

class LoadingScreen extends React.Component {
  render() {
    return (
      <div className='loading-screen d-flex flex-fill text-white bg-main h-100 align-items-center justify-content-center'>
        <i className="fas fa-circle-notch fa-spin"/>
      </div>
    );
  }
}

export default LoadingScreen;
