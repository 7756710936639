import React from 'react';
import { connect } from 'react-redux';

class RoleAware extends React.Component {
  isAccessible() {
    const { roles, user } = this.props;

    return roles && roles.some(role => user[role]);
  }

  render() {
    if (this.isAccessible()) {
      return this.props.children;
    }

    return null;
  }
}

const mapStateToProps = state => ({
  user: state.user.currentUser
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleAware);
