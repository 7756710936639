const CONFIG = {
  apiKey: "AIzaSyCln8nNPuE_e8FsmkX6e62BFy4eNuIe1So",
  authDomain: "time-sednor.firebaseapp.com",
  databaseURL: "https://time-sednor.firebaseio.com",
  projectId: "time-sednor",
  storageBucket: "",
  messagingSenderId: "445110877658",
  appId: "1:445110877658:web:c7ce9d7d93a1a271"
};

export default CONFIG;
